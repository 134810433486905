import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        metricsGridItem: {
            flexGrow: 1,
        },

        statsCard: {
            height: '100%',
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
                borderRadius: '2px',
            },
        },
        statsCardTitleText: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.custom.grey.g600,
            marginBottom: theme.spacing(1),
        },
        statsCardValueText: {
            fontSize: theme.typography.pxToRem(25),
            fontWeight: 500,
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(20),
            },
        },
        statsCardLabelText: {
            fontSize: theme.typography.pxToRem(12),
            letterSpacing: '0.005px',
            wordSpacing: '0.25px',
            fontWeight: 300,
            color: theme.palette.custom.grey.g600,
        },
        statsCardLabel: {
            paddingBottom: theme.spacing(1),
            fontWeight: 500,
        },
        titleText: {
            marginBottom: theme.spacing(2),
        },

        // Revenue
        filters: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },

        // Top Page
        topPostsCard: {
            padding: theme.spacing(1, 0),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        topPostRow: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        shadedRow: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        topPostRowContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: theme.spacing(0, 2),
        },
        topPostTitle: {
            color: theme.palette.common.black,
            paddingBottom: theme.spacing(0.5),
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        topPostSlug: {
            color: theme.palette.common.black,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
        },
        topPostFilterContainer: {
            padding: theme.spacing(1, 0),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        topPostShowAllButton: {
            marginLeft: theme.spacing(1),
        },
    }),
);

export default useStyles;
