import React from 'react';
import useStyles from '../styles';
import { TopPageViewStatsWithDataProps } from '.';
import DateDropdowns from '../Filters/DateDropdowns';
import { EnhancedDropdown } from 'molecules';
import { GridContainer, GridItem, MONTHS } from '@elevatormedia/duffel-bag';
import {
    Paper,
    Typography,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    Button,
} from '@material-ui/core';
import { Refresh } from 'mdi-material-ui';
import { DOMAIN_BASE } from '../../../config/Nav';
import { PageViewStat, PageViewStats, StatsDate } from 'types/analytics';
import { getCurrentMonthIndex, getCurrentYear } from 'lib/utils';
import clsx from 'clsx';

const TopPageViewStats = ({
    pageViewStats,
    loading,
    error,
    refetch,
    overview,
    dashboard,
}: TopPageViewStatsProps) => {
    const classes = useStyles();

    const [showCount, setShowCount] = React.useState<number>(5);
    const countOptionsDefault = [5, 10, 20, 30];

    const [month, setMonth] = React.useState(getCurrentMonthIndex());
    const [year, setYear] = React.useState(getCurrentYear());

    const handleShowAll = () => {
        if (pageViewStats && pageViewStats.length) setShowCount(pageViewStats.length);
    };

    const refreshStats = async ({ newMonth, newYear }: StatsDate) => {
        await refetch({ month: newMonth + 1, year: newYear });
    };

    const handleMonthChange = async (value: string) => {
        const newIndex = MONTHS.findIndex((val) => val === value);
        setMonth(newIndex);
        await refreshStats({
            newMonth: newIndex,
            newYear: year,
        });
    };

    const handleYearChange = async (value: number) => {
        setYear(value);
        await refreshStats({
            newMonth: month,
            newYear: value,
        });
    };

    const renderStatsCard = () => {
        let count = 0;
        pageViewStats.map((stat) => {
            count += stat.pageViews;
        });

        return (
            <Paper className={classes.statsCard}>
                <Typography
                    variant={'body1'}
                    color={'textSecondary'}
                    className={classes.statsCardTitleText}
                >
                    Site
                </Typography>
                <GridContainer justifyContent={'space-between'} paddingY={1}>
                    <GridItem>
                        <Typography className={classes.statsCardValueText}>
                            {(count / 1000).toFixed(1) + 'k'}
                        </Typography>
                    </GridItem>
                    <GridItem>
                        <Typography className={classes.statsCardValueText}>
                            {pageViewStats.length} posts
                        </Typography>
                    </GridItem>
                </GridContainer>
            </Paper>
        );
    };

    const renderPosts = () => (
        <List disablePadding>
            {pageViewStats
                .slice(0, showCount)
                .map((page: PageViewStat, index: number) => {
                    const siteUrl = `${DOMAIN_BASE}/${page.slug}`;

                    return (
                        <ListItem
                            key={index}
                            className={clsx(
                                classes.topPostRow,
                                index % 2 !== 0 && classes.shadedRow,
                            )}
                        >
                            <Typography>{index + 1}</Typography>
                            <div className={classes.topPostRowContent}>
                                <Typography
                                    component={'a'}
                                    href={siteUrl}
                                    variant={'body2'}
                                    className={classes.topPostTitle}
                                >
                                    {page.title}
                                </Typography>
                                <Typography
                                    component={'a'}
                                    href={siteUrl}
                                    variant={'caption'}
                                    className={classes.topPostSlug}
                                >{`/${page.slug}`}</Typography>
                            </div>
                            <Typography>{page.pageViews.toLocaleString()}</Typography>
                        </ListItem>
                    );
                })}
        </List>
    );

    const renderContent = () => {
        if (loading)
            return (
                <GridContainer
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <GridItem>
                        <CircularProgress size={40} />
                    </GridItem>
                </GridContainer>
            );
        else if (error)
            return (
                <GridContainer
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <GridItem>
                        <Typography>Error retrieving statistics</Typography>
                    </GridItem>
                </GridContainer>
            );
        else {
            if (pageViewStats.length) countOptionsDefault.push(pageViewStats.length);

            return (
                <GridContainer direction={'row'} spacing={2}>
                    <GridItem xs={12} sm={6}>
                        <Paper className={classes.topPostsCard}>{renderPosts()}</Paper>
                    </GridItem>
                </GridContainer>
            );
        }
    };

    const renderStats = () => {
        return (
            <GridContainer direction={'column'}>
                <GridItem>
                    <DateDropdowns
                        month={month}
                        handleMonthChange={handleMonthChange}
                        year={year}
                        handleYearChange={handleYearChange}
                    />
                </GridItem>
                <GridItem xs={12} md={6} paddingY={1}>
                    {renderStatsCard()}
                </GridItem>
            </GridContainer>
        );
    };

    if (!overview) {
        return (
            <GridContainer direction={'column'} className={classes.root}>
                {!dashboard && renderStats()}
                <GridItem>
                    <GridContainer
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <GridItem>
                            <Typography
                                color={'textSecondary'}
                                gutterBottom
                                variant={'subtitle1'}
                            >
                                Top Posts
                            </Typography>
                            <Typography
                                color={'textSecondary'}
                                gutterBottom
                                variant={'subtitle2'}
                            >
                                last 30 days
                            </Typography>
                            <div className={classes.topPostFilterContainer}>
                                <EnhancedDropdown
                                    selectedValue={showCount}
                                    handleSelectedValueChange={(value) =>
                                        setShowCount(value as number)
                                    }
                                    dropdownLabel={'Show Count'}
                                    dropdownOptions={countOptionsDefault}
                                />
                                <Button
                                    size={'small'}
                                    variant={'text'}
                                    color={'primary'}
                                    onClick={handleShowAll}
                                    className={classes.topPostShowAllButton}
                                >
                                    Show All {pageViewStats ? pageViewStats.length : ''}
                                </Button>
                            </div>
                        </GridItem>
                        <GridItem>
                            <IconButton onClick={() => refetch()}>
                                <Refresh color={'primary'} />
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem>{renderContent()}</GridItem>
            </GridContainer>
        );
    } else {
        return renderStatsCard();
    }
};

export interface TopPageViewStatsProps extends TopPageViewStatsWithDataProps {
    loading: boolean;
    error: any;
    refetch: Function;
    pageViewStats: PageViewStats;
}

export default TopPageViewStats;
