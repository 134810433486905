import gql from 'graphql-tag';

export const SUBMISSION_STATS = gql`
    query monthlySubmissionStats($month: Int, $year: Int) {
        monthlySubmissionStats(month: $month, year: $year) {
            type
            count
            revenue
            accepted
            declined
        }
    }
`;

export const USER_STATS = gql`
    query userStats {
        userStats {
            groups {
                Standard
                Team
                Admin
            }
            preferred {
                true
                false
            }
        }
    }
`;

export const NEW_USER_STATS = gql`
    query newUserStats {
        newUserStats
    }
`;

export const REVENUE_STATS = gql`
    query revenueStats($month: Int!, $year: Int!) {
        revenueStats(month: $month, year: $year)
    }
`;

export const TOP_PAGE_VIEW_STATS = gql`
    query allPageViewStats {
        allPageViewStats {
            title
            slug
            pageViews
        }
    }
`;
