import React from 'react';
import { MONTHS, YEARS, GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { EnhancedDropdown } from 'molecules';

const DateDropdowns = ({
    month,
    year,
    handleMonthChange,
    handleYearChange,
}: DateDropdownsProps) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GridContainer
            direction={'row'}
            justifyContent={mobile ? 'space-between' : 'flex-start'}
            alignItems={'center'}
        >
            <GridItem>
                <Box paddingY={2}>
                    <EnhancedDropdown
                        selectedValue={MONTHS[month]}
                        handleSelectedValueChange={handleMonthChange}
                        dropdownLabel={'Month'}
                        dropdownOptions={MONTHS}
                    />
                </Box>
            </GridItem>
            <GridItem>
                <Box paddingX={mobile ? 0 : 2} paddingY={2}>
                    <EnhancedDropdown
                        selectedValue={year}
                        handleSelectedValueChange={handleYearChange}
                        dropdownLabel={'Year'}
                        dropdownOptions={YEARS}
                    />
                </Box>
            </GridItem>
        </GridContainer>
    );
};

export type DateDropdownsProps = {
    month: number;
    handleMonthChange: (month: string) => void;
    year: number;
    handleYearChange: (year: number) => void;
};

export default DateDropdowns;
