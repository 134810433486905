/**
 * Normalize link removes protocols and the www. prefixes and appends a '//'
 * to allow hrefs to open non-www links in a new window rather than appending them
 * to the local domain (e.g, submissions.elevatormag.com/user/test/facebook.com/test)
 */
export const normalizeLink = (linkRaw: string, addPrefix = true) => {
    return (addPrefix ? '//' : '') + linkRaw.replace('https://', '').replace('www.', '');
};

/**
 * Returns a mapping of viewport width size images -> uri
 * from an image source set
 */
export const getDownloadURL = (sourceSet: string) => {
    const sets = sourceSet.split(',');
    const sourceSetMap: Record<string, string> = {};

    sets.forEach((sourceSetValue) => {
        const [uri, viewPortSize] = sourceSetValue.trim().split(' ');
        sourceSetMap[viewPortSize] = uri;
    });

    return sourceSetMap;
};

/**
 * Compares that two arrays are equal in length
 * and values by position
 */
export const arraysAreEqual = (a: Array<any>, b: Array<any>) => {
    if (a.length !== b.length) {
        return false;
    }

    /**
     * Adapted from: https://masteringjs.io/tutorials/fundamentals/compare-arrays
     */
    return a.every((arrayElement, index) => arrayElement === b[index]);
};

export const formatDateOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
};

export const getCurrentMonthIndex = () => new Date().getMonth();

export const getCurrentYear = () => new Date().getFullYear();
