import React from 'react';
import TopPageViewStats from './TopPageViewStats';
import { TOP_PAGE_VIEW_STATS } from '../../../lib/graphql/queries/analytics';
import { useQuery } from '@apollo/client';

const TopPageViewStatsWithData = ({
    overview,
    dashboard,
}: TopPageViewStatsWithDataProps) => {
    const { data, loading, networkStatus, error, refetch } = useQuery(
        TOP_PAGE_VIEW_STATS,
        {
            notifyOnNetworkStatusChange: true,
        },
    );

    const pageViewStats = data && data.allPageViewStats ? data.allPageViewStats : [];
    const isLoading = loading || networkStatus === 4;

    return (
        <TopPageViewStats
            loading={isLoading}
            error={error}
            refetch={refetch}
            pageViewStats={pageViewStats}
            dashboard={dashboard}
            overview={overview}
        />
    );
};

export interface TopPageViewStatsWithDataProps {
    overview?: boolean;
    dashboard?: boolean;
}

TopPageViewStatsWithData.defaultProps = {
    overview: false,
    dashboard: false,
};

export default TopPageViewStatsWithData;
